// custom typefaces
import "@fontsource/pt-sans/latin-400.css" 
import "@fontsource/pt-sans-narrow/latin-700.css" 
import "@fontsource/open-sans/latin-300.css" 
import "@fontsource/open-sans/latin-300-italic.css" 
import "@fontsource/open-sans/latin-400.css" 
import "@fontsource/open-sans/latin-400-italic.css" 
import "@fontsource/open-sans/latin-600.css" 
import "@fontsource/lato/latin-700.css" 

import Prism from 'prism-react-renderer/prism'
;(typeof global !== 'undefined' ? global : window).Prism = Prism // gotta do this one otherwise it'll say Prism is not defined
require('prismjs/components/prism-go')
require('prismjs/components/prism-php')
require('prismjs/components/prism-pug')
require('prismjs/components/prism-markdown')
require('prismjs/components/prism-diff')
require('prismjs/components/prism-css')
require('prismjs/components/prism-bash')
require('prismjs/components/prism-markup')
require('prismjs/components/prism-powershell')
require('prismjs/components/prism-csharp')
require('prismjs/components/prism-aspnet')
require('prismjs/components/prism-graphql')
require('prismjs/components/prism-toml')
require('prismjs/components/prism-ignore')

const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav")
    if (skipLink) {
      skipLink.focus()
    }
  }
};

export {
  onRouteUpdate
}
